import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import StudentProfile from '../src/pages/StudentProfile/StudentProfile';
import TrainerDashboard from './pages/TrainerDashboard/TrainerDashboard';
import LoginPage from './pages/LoginPage/LoginPage';
import AssignedCourse from './pages/AssignedCourse/AssignedCourse';
import AssignedCourseDetails from './pages/AssignedCourseDetails/AssignedCourseDetails';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProtectedRoute from './authentication/protectedRoute';
import AppLayout from './components/AppLayout';
import AssignmentManagement from './pages/AssignmentManagement/AssignmentManagement';
import UnitDetail from './components/UnitDetail/UnitDetail';
import ProfileSectionPage from './pages/ProfileSectionPage/ProfileSectionPage';

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0
      }
    }
  })
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route element={<ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>}>
              <Route exact path='/trainerdashboard' element={<TrainerDashboard />} />
              <Route exact path='/students' element={<StudentProfile />} />
              <Route exact path='/assignedcourse' element={<AssignedCourse />} />
              <Route exact path='/assignedcoursedetails/:courseid' element={<AssignedCourseDetails />} />
              <Route exact path='/assignmentmanagement' element={<AssignmentManagement />} />
              <Route exact path='/unit/1' element={<UnitDetail />} />
              <Route exact path='/profile' element={<ProfileSectionPage />} />
            </Route>
            <Route exact path='/' element={<LoginPage />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
