import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "../../config/restApiAuth";

export function useTrainer() {
  const { isLoading, data: user } = useQuery({
    queryKey: ["user"],
    queryFn: getCurrentUser,
  });

  return { isLoading, user, isAuthenticated: user?.user?.role === "trainer" };
}

