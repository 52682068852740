import React from 'react'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import TrainerMainSection from '../../components/TrainerMainSection/TrainerMainSection'

function TrainerDashboard() {
  return (
    <div style={{display:'flex'}}>
        <Sidebar />
        <TrainerMainSection />
    </div>
  )
}

export default TrainerDashboard