import {useNavigate} from 'react-router-dom'
import {useMutation , useQueryClient} from '@tanstack/react-query'
import { userLogin } from '../../config/restApiAuth';


export function useLogin()
{
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {mutate:login,isLoading} = useMutation({
        mutationFn: ({email,password}) => {
            return userLogin({email,password})
        },
        onSuccess: (user) => {
            queryClient.setQueryData(["user"], user);
            navigate('/trainerdashboard')
        },
        onError: (error) => {
            console.log(error);
            alert("Invalid credentials");
        }
    });

    return {login,isLoading};
}