import React, { useState } from 'react'
import './AssignedCourseCard.css'
import { useNavigate } from 'react-router-dom';

function AssignedCourseCard({ id, title, description }) {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);  // State to toggle description
    
    const handleClick = () => {
        navigate(`/assignedcoursedetails/${id}`);  // Navigate with course id
    };

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedDescription = description.length > 100 ? description.substring(0, 100) + "..." : description;

    return (
        <div className='assigned-course-card'>
            <div className="assigned-course-heading">
                <p className='assigned-course-name'>{title}</p>
                <p className='assigned-course-description'>
                     {description}
                </p>
                
            </div>
            <div className="assigned-course-details">
                <button onClick={handleClick} className='assigned-course-details-btn'>
                    View Details
                </button>
            </div>
        </div>
    )
}

export default AssignedCourseCard;
