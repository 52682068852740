import React from 'react';
import './AssignmentLibraryCard.css';

function AssignmentLibraryCard({ assignment }) {
  return (
    <div className='assignment-library-card'>
        <div className='assignment-library-card-container'>
          <div className='assignment-library-card-header'>
              <p>{assignment.title}</p>
          </div>
          <div className='assignment-library-card-body'>
              <p>{assignment.description}</p>
              <button className='view-assignment-button'>View Assignment</button>
          </div>
        </div>
    </div>
  );
}

export default AssignmentLibraryCard;
