import React, { useEffect, useState } from 'react'
import './TrainerMainSection.css'
import TrainerAnimatedBackground from '../TrainerAnimatedBackground/TrainerAnimatedBackground'
import CalendarComponent from '../CalendarComponent/CalendarComponent'
import ClassSchedulerCard from '../ClassSchedulerCard/ClassSchedulerCard'
import { getCurrentUser } from '../../config/restApiAuth'

function TrainerMainSection() {
  const [username, setUsername] = useState(''); 

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getCurrentUser(); 
        setUsername(userData.user.name); 
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, []);
  return (
    <div className="student-main-section">
      <div className="student-main-container">
        <div className="heading-bell-container">
          <p className="student-welcome-heading">Welcome! {username}</p>
          <div className="profile-notification-container">
            {/* <span className="profile-icon material-symbols-outlined">
              account_circle
            </span> */}
            <p className="bell-icon material-symbols-outlined">notifications</p>
          </div>
        </div>
        <div className="student-upcoming-events">
          <TrainerAnimatedBackground />
        </div>
        <div className="Slot-calendar-section">
          <div className="calendar-and-table">
            <div className="Basic-section">
              <ClassSchedulerCard />
            </div>
            <div className="calendar-section">
              <CalendarComponent />

            </div>
          </div>
        </div>
        <div className="upload-view-assignment">
          <div className="pending-assignment-section">

          </div>

          <div className="upload-pending-assignment">

          </div>
        </div>


      </div>
    </div>
  )
}

export default TrainerMainSection