import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import { supabase } from '../../config/supabaseClient';
import './ClassSchedulerCard.css';

const ClassSchedulerCard = () => {
  const [showForm, setShowForm] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [unitName, setUnitName] = useState('');
  const [date, setDate] = useState('');
  const [classDescription, setClassDescription] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');
  const [showSlots, setShowSlots] = useState(false);
  const [courses, setCourses] = useState([]);
  const [units, setUnits] = useState([]);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      const { data, error } = await supabase.from('courses').select('*');
      if (error) {
        console.error('Error fetching courses:', error);
      } else {
        setCourses(data);
      }
    };

    const fetchSlots = async () => {
      const { data, error } = await supabase.from('slots').select('*');
      if (error) {
        console.error('Error fetching slots:', error);
      } else {
        setSlots(data);
      }
    };

    fetchCourses();
    fetchSlots();
  }, []);

  useEffect(() => {
    const fetchUnits = async () => {
      if (courseName) {
        const { data, error } = await supabase.from('units').select('*').eq('course_id', courseName);
        if (error) {
          console.error('Error fetching units:', error);
        } else {
          setUnits(data);
        }
      }
    };

    fetchUnits();
  }, [courseName]);

  const handleNextClick = () => {
    if (!courseName || !unitName || !date || !classDescription) {
      Swal.fire('Error', 'All fields are required before proceeding.', 'error');
      return;
    }
    setShowSlots(true);
  };

  const handleBackClick = () => {
    if (showSlots) {
      setShowSlots(false);
    } else {
      setShowForm(false);
    }
  };

  const handleSubmit = async () => {
    if (!selectedSlot) {
      Swal.fire('Error', 'Please select a time slot.', 'error');
      return;
    }

    try {
      const [startTime, endTime] = selectedSlot.split(' - ');
      const { data, error } = await supabase.from('classes').insert([
        {
          course_id: courseName,
          unit_id: unitName,
          date,
          class_description: classDescription,
          start_time: startTime,
          end_time: endTime,
          slot_time: selectedSlot,
        },
      ]);

      if (error) {
        Swal.fire('Error', 'Failed to schedule the class.', 'error');
      } else {
        Swal.fire('Success', 'Class scheduled successfully!', 'success');
        resetForm();
        // onClassScheduled();
      }
    } catch (error) {
      Swal.fire('Error', 'An unexpected error occurred.', 'error');
    }
  };

  const resetForm = () => {
    setCourseName('');
    setUnitName('');
    setDate('');
    setClassDescription('');
    setSelectedSlot('');
    setShowForm(false);
    setShowSlots(false);
  };

  return (
    <motion.div
      className="class-scheduler-container"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      {!showSlots ? (
        <motion.div
          className="schedule-form-container"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="form-title">Schedule a Class</h3>
          <div className="dropdown-input">
            <motion.div className="form-field">
              <label>Course Name</label>
              <select
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                required
              >
                <option value="">Select Course</option>
                {courses.map(course => (
                  <option key={course.id} value={course.id}>{course.name}</option>
                ))}
              </select>
            </motion.div>

            <motion.div className="form-field">
              <label>Unit Name</label>
              <select
                value={unitName}
                onChange={(e) => setUnitName(e.target.value)}
                required
              >
                <option value="">Select Unit</option>
                {units.map(unit => (
                  <option key={unit.id} value={unit.id}>{unit.name}</option>
                ))}
              </select>
            </motion.div>
          </div>

          <motion.div className="form-field">
            <label>Date</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </motion.div>

          <motion.div className="form-field">
            <label>Class Description</label>
            <textarea
              value={classDescription}
              onChange={(e) => setClassDescription(e.target.value)}
              placeholder="Enter class description"
              required
            ></textarea>
          </motion.div>

          <div className="button-group">
            <motion.button className="back-btn" onClick={handleBackClick}>
              Back
            </motion.button>
            <motion.button className="next-btn" onClick={handleNextClick}>
              Next
            </motion.button>
          </div>
        </motion.div>
      ) : (
        <>
          <h3 className="form-title">Select a Slot</h3>
          <motion.div
            className="slots-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {slots.map((slot) => (
              <motion.div
                key={slot.id}
                className={`slot ${selectedSlot === slot.time_slot ? 'selected' : ''}`}
                onClick={() => setSelectedSlot(slot.time_slot)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {slot.time_slot}
              </motion.div>
            ))}
          </motion.div>

          <div className="button-group">
            <motion.button className="back-btn" onClick={handleBackClick}>
              Back
            </motion.button>
            <motion.button className="submit-btn1" onClick={handleSubmit}>
              Schedule
            </motion.button>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default ClassSchedulerCard;
