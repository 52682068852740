import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import { FaEdit, FaFacebook, FaTwitter, FaLinkedin, FaEye, FaEyeSlash, FaInstagram } from 'react-icons/fa';
import './ProfileSection.css';
import Swal from 'sweetalert2';

// Ensure Modal is properly set for accessibility
Modal.setAppElement('#root');

const ProfileSection = () => {
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    userName: 'John Doe',
    role: 'Trainer',
    phone: '+123456789',
    email: 'johndoe@example.com',
    address: '123 Main St, City, Country',
    occupation: 'Teacher',
    dateOfBirth: '1990-01-01',
    facebook: 'https://facebook.com',
    twitter: 'https://twitter.com',
    linkedin: 'https://linkedin.com',
    instagram: 'https://instagram.com',
    profileImage: null
  });

  const [errors, setErrors] = useState({});
  
  const validateForm = () => {
    const newErrors = {};
    if (!formData.userName) newErrors.userName = 'User Name is required';
    if (!formData.email.includes('@')) newErrors.email = 'Invalid email';
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.address) newErrors.address = 'Address is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (!validateForm()) return;

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save the changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData({ ...formData });
        setShowModal(false);
        Swal.fire('Saved!', 'Your profile has been updated.', 'success');
      }
    });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFormData({ ...formData, profileImage: file });
    }
  }, [formData]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  const handleRemoveImage = () => {
    setFormData({ ...formData, profileImage: null });
  };
  return (
    <div className="profile-container">
      <div className="profile-banner">
        <div className="banner-background"></div>
        
        <div className="user-info">
        <img
            src={formData.profileImage ? URL.createObjectURL(formData.profileImage) : 'https://images.pexels.com/photos/1542085/pexels-photo-1542085.jpeg'}
            alt="Profile"
            className="profile-image"
          />
          <div className="user-info-detail">
            <h2 className="user-name">{formData.userName}</h2>
            <p className="user-role">{formData.role}</p>
          </div>
        </div>
        <div className="edit-icon" onClick={() => setShowModal(true)}>
          <FaEdit size={24} />
        </div>
        <div className="social-media-icons">
          <a href={formData.facebook} target="_blank" rel="noopener noreferrer">
            <FaFacebook size={20} />
          </a>
          <a href={formData.twitter} target="_blank" rel="noopener noreferrer">
            <FaTwitter size={20} />
          </a>
          <a href={formData.linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={20} />
          </a>
          <a href={formData.instagram} target="_blank" rel="noopener noreferrer">
            <FaInstagram size={20} />
          </a>
        </div>
      </div>

      <div className="details-section">
        {/* User details card */}
        <div className="user-details-card">
          <h3>User Details</h3>
          <p><strong>Phone:</strong> {formData.phone}</p>
          <p><strong>Email:</strong> {formData.email}</p>
          <p><strong>Address:</strong> {formData.address}</p>
          <p><strong>Occupation:</strong> {formData.occupation}</p>
          <p><strong>Date of Birth:</strong> {formData.dateOfBirth}</p>
        </div>

        {/* Modal for editing profile */}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Edit Profile</h3>
              <form className="modal-form">
               {/* Profile Picture Dropzone */}
                <div className="form-group">
                  <label>Profile Picture:</label>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the image here...</p>
                    ) : (
                      <p>Drag & drop a profile picture here, or click to select one</p>
                    )}
                  </div>
                  {formData.profileImage && (
                    <div className="image-preview">
                      <img
                        src={URL.createObjectURL(formData.profileImage)}
                        alt="Profile Preview"
                      />
                      <button type="button" onClick={handleRemoveImage} className="remove-image-btn">Remove Image</button>
                    </div>
                  )}
                </div>

                <div className="form-group1">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={errors.email ? 'input-error' : ''}
                  />
                  {errors.email && <span className="error-text">{errors.email}</span>}
                </div>
                <div className="form-group">
                  <label>Phone:</label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={errors.phone ? 'input-error' : ''}
                  />
                  {errors.phone && <span className="error-text">{errors.phone}</span>}
                </div>


                </div>

                

                
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={errors.address ? 'input-error' : ''}
                  />
                  {errors.address && <span className="error-text">{errors.address}</span>}
                </div>

                <div className="sociallink1">
                <div className="form-group">
                  <label>Facebook Link:</label>
                  <input
                    type="url"
                    name="facebook"
                    value={formData.facebook}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label>Twitter Link:</label>
                  <input
                    type="url"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleChange}
                  />
                </div>

                </div>

                <div className="sociallink2">
                <div className="form-group">
                  <label>LinkedIn Link:</label>
                  <input
                    type="url"
                    name="linkedin"
                    value={formData.linkedin}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label>Instagram Link:</label>
                  <input
                    type="url"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleChange}
                  />
                </div>

                </div>

                

                

                <div className="modal-actions">
                  <button type="button" onClick={handleSave} className="save-btn">Save Changes</button>
                  <button type="button" onClick={() => setShowModal(false)} className="cancel-btn">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="reset-password-card">
          <h3 className="reset-heading">Reset Password</h3>
          <form>
        <div className="reset-input">
          <label>Current Password:</label>
          <div className="password-input-wrapper">
            <input
              type={showPassword.currentPassword ? 'text' : 'password'}
              name="currentPassword"
            />
            <span
              className="password-toggle-icon"
              onClick={() => togglePasswordVisibility('currentPassword')}
            >
              {showPassword.currentPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <div className="reset-input">
          <label>New Password:</label>
          <div className="password-input-wrapper">
            <input
              type={showPassword.newPassword ? 'text' : 'password'}
              name="newPassword"
            />
            <span
              className="password-toggle-icon"
              onClick={() => togglePasswordVisibility('newPassword')}
            >
              {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <div className="reset-input">
          <label>Confirm Password:</label>
          <div className="password-input-wrapper">
            <input
              type={showPassword.confirmPassword ? 'text' : 'password'}
              name="confirmPassword"
            />
            <span
              className="password-toggle-icon"
              onClick={() => togglePasswordVisibility('confirmPassword')}
            >
              {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <button type="submit">Reset Password</button>
      </form>
        </div>
      </div>

      {/* Stats section */}
      <div className="stats-section">
        <div className="small-card">
          <h4>Courses Assigned</h4>
          <p>5</p>
        </div>
        <div className="small-card">
          <h4>Students Assigned</h4>
          <p>120</p>
        </div>
        <div className="small-card">
          <h4>Classes Held</h4>
          <p>35</p>
        </div>
        <div className="small-card">
          <h4>Performance Rating</h4>
          <p>4.8/5</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
