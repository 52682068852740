import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { supabase } from "../../config/supabaseClient"; 
import "./StudentProfile.css";
import { Sidebar } from "../../components/Sidebar/Sidebar";

const StudentProfile = () => {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const studentsPerPage = 6; 

  useEffect(() => {
    const fetchStudents = async () => {
      const { data, error } = await supabase
        .from('students') 
        .select('*'); 

      if (error) {
        console.error("Error fetching students:", error);
      } else {
        setStudents(data || []); // Default to empty array if no data
      }
    };

    fetchStudents();
  }, []);

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); 
  };

  // Filter students based on the search term (by name or course)
  const filteredStudents = students.filter(
    (student) =>

      student.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.course?.toLowerCase().includes(searchTerm.toLowerCase())

  );

  const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

  // Handle card click (opening modal)
  const handleCardClick = (student) => {
    setSelectedStudent(student);
    setShowModal(true);
  };

  // Handle close modal
  const handleClose = () => {
    setShowModal(false);
    setSelectedStudent(null);
  };

  // Handle previous page click
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle next page click
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * studentsPerPage;
  const endIndex = startIndex + studentsPerPage;
  const displayedStudents = filteredStudents.slice(startIndex, endIndex);

  return (
    <div className="StudentProfileSection">
      <Sidebar />

<div className="student-profile-container">

  <div className="Profile_Header">
  <h2 className="dynamic-title">
        <span className="dynamic-title-main">Assigned Students</span>
      </h2>

      <input
        type="text"
        placeholder="Search by name or course..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-bar"
      />
  </div>
      
      

      <div className="content-wrapper">
        <div className="card-container">
          {displayedStudents.map((student) => (
            <motion.div
              key={student.id} 
              className="student-card-rectangular"
              whileHover={{ scale: 1.1, rotate: 2 }}
              onClick={() => handleCardClick(student)}
            >
              <div className="card-content">
                <img
                  src={student.image || "default-image.png"}  
                  alt={student.name || "No Name"}  
                  className="student-img-rectangular"
                />
                <div className="student-tags">
                  <h3 className="student-name">{student.name || "No Name"}</h3> 
                </div>
                <div className="card-overlay">
                  <div className="card-details">
                    <h3>{student.name || "No Name"}</h3>
                    {/* <p>{student.course || "No Course"}</p> */}
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{ width: `${student.progress || 0}%` }} // Fallback to 0% if undefined
                      ></div>
                    </div>
                    <p className="progress-label">
                      {student.progress || 0}% Progress
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="pagination-controls">
          <motion.button
            className="pagination-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Previous
          </motion.button>

          <span className="page-info">
            {currentPage} / {totalPages}
          </span>

          <motion.button
            className="pagination-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Next
          </motion.button>
        </div>
      </div>

      {selectedStudent && (
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          className="full-screen-modal"
        >
          <div className="modal-content-wrapper">
            <Modal.Header className="modal-header">
              <h3>
                {selectedStudent.name} - {selectedStudent.course}
              </h3>
              <Button
                variant="outline-light"
                className="close-modal"
                onClick={handleClose}
              >
                X
              </Button>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="modal-main-content">
                <div className="student-info">
                  <img
                    src={selectedStudent.image || "default-image.png"} 
                    alt={selectedStudent.name}
                    className="student-modal-img"
                  />
                  <p>
                    <strong>Email:</strong> {selectedStudent.email || "N/A"}
                  </p>
                  <p>
                    <strong>Phone:</strong> {selectedStudent.phone || "N/A"}
                  </p>
                </div>

                <div className="course-info">
                  <h4>Course Details</h4>
                  <p>{selectedStudent.courseDetails || "No details available"}</p>
                  <div className="progress-section">
                    <h5>Progress Breakdown</h5>
                    <div className="progress-bar-large">
                      <div
                        className="progress"
                        style={{ width: `${selectedStudent.progress || 0}%` }}
                      ></div>
                    </div>
                    <p className="progress-label-large">
                      {selectedStudent.progress || 0}% Progress
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
              <Button variant="primary">Update Progress</Button>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </div>

    </div>
    
  );
};

export default StudentProfile;
