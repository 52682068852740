import React, { useState } from 'react';
import './CreateAssignmentModal.css';

function CreateAssignmentModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="assignment-modal assignment-modal-show">
      <div className="assignment-modal-content">
        <span className="assignment-modal-close-button" onClick={onClose}>
          &times;
        </span>
        {/* <h2>Create New Assignment</h2> */}
        <form>
          <div className="assignment-form-group">
            <label htmlFor="assignment-title">Assignment Title</label>
            <input type="text" id="assignment-title" placeholder="Enter title" />
          </div>

          <div className="assignment-form-group">
            <label htmlFor="assignment-description">Assignment Description</label>
            <textarea id="assignment-description" placeholder="Enter description"></textarea>
          </div>

          <div className="assignment-form-group">
            <label htmlFor="submission-deadline">Submission Deadline</label>
            <input type="date" id="submission-deadline" />
          </div>

          <div className="assignment-form-group assignment-drag-drop">
            <p>Drag & Drop to Upload Files</p>
          </div>

          <div className="assignment-form-actions">
            <button type="submit">Submit</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAssignmentModal;
