import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { supabase } from "../../config/supabaseClient";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarComponent.css";
import Swal from "sweetalert2";

const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);

    const triggerNotification = (event) => {
      // Triggering a browser notification
      if (Notification.permission === "granted") {
        new Notification(`Upcoming Class Reminder`, {
          body: `${event.title} is starting in 5 minutes!`,
        });
      }
      
      Swal.fire({
        title: 'Upcoming Class Reminder',
        text: `${event.title} is starting in 5 minutes!`,
        icon: 'info',
        showConfirmButton: true,
        confirmButtonText: 'Got it',
        timer: 7000, 
        timerProgressBar: true
      });
    };
    useEffect(() => {
      const checkForUpcomingEvents = () => {
        const currentTime = new Date();
        
        events.forEach((event) => {
          const eventTime = new Date(event.start);
          const timeDifference = eventTime - currentTime;
          
          if (timeDifference > 0 && timeDifference <= 300000) {
            setTimeout(() => {
              triggerNotification(event);
            }, timeDifference - 300000);  
          }
        });
      };
    
      checkForUpcomingEvents();
    }, [events]);
  
  

  // const fetchEvents = async () => {
  //   try {
  //     const { data, error } = await supabase.from("classes").select("*");
  //     if (error) {
  //       console.error("Error fetching events:", error);
  //     } else {
  //       const formattedEvents = data.map((event) => ({
  //         title: `Course: ${event.course_id} - Unit: ${event.unit_id}`,
  //         start: new Date(`${event.date}T${event.start_time}`),
  //         end: new Date(`${event.date}T${event.end_time}`),
  //       }));
  //       setEvents(formattedEvents);
  //     }
  //   } catch (error) {
  //     console.error('An unexpected error occurred while fetching events:', error);
  //   }
  // };

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const { data, error } = await supabase
        .from("classes")
        .select(`
          date,
          start_time,
          end_time,
          courses (name),
          units (name)
        `);
  
      if (error) {
        console.error("Error fetching events:", error);
      } else {
        // Map the events to display them on the calendar
        const formattedEvents = data.map((event) => ({
          title: `Course: ${event.courses.name} - Unit: ${event.units.name}`,
          start: new Date(`${event.date}T${event.start_time}`),  // Combine date and start time
          end: new Date(`${event.date}T${event.end_time}`),      // Combine date and end time
        }));
        setEvents(formattedEvents);
      }
    } catch (error) {
      console.error('An unexpected error occurred while fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%", width: "100%" }}
        popup
      />
    </div>
  );
};

export default CalendarComponent;
