import React from 'react'
import './TrainerAnimatedBackground.css'

function TrainerAnimatedBackground() {
    return (
        <div className="animated-background-section">
            <div className="context">
                {/* <p>Upcoming Class</p> */}
                <div className="upcoming-lecture-section">
                    <p>Lecture 1: Foundation to English Grammar</p>
                    <div className="join-now-meeting-btn">
                        <button>
                            <div className="original">Join Now</div>
                            <div className="letters">
                                <span>J</span>
                                <span>O</span>
                                <span>I</span>
                                <span>N</span>
                                <span>&nbsp;</span>
                                <span>N</span>
                                <span>O</span>
                                <span>W</span>
                            </div>
                        </button>
                    </div>
                </div>

            </div>


            <div className="area" >
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div >
        </div>
    )
}

export default TrainerAnimatedBackground